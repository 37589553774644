/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */

@import '~bootstrap/scss/bootstrap';
@import '~@angular/material/theming';
@import '~@angular/cdk/overlay-prebuilt.css';
@import './assets/scss/mixins';
@import './assets/scss/warning-box';
@import './assets/scss/card';
@import './assets/scss/speech';
@import './assets/scss/font';
@import './assets/scss/layout';
@import './assets/scss/inputs';
@import './assets/scss/buttons';
@import './assets/scss/tooltip';
@import './assets/scss/icons';
@import './assets/scss/combobox';
@import './assets/scss/checkbox';
@import './assets/scss/form-control';
@import './assets/scss/mat-form-field';
@import './assets/scss/datepicker';
@import './assets/scss/modal';
@import './assets/scss/file-uploader';
@import './assets/scss/responsive';
@import './assets/scss/firefox';
@import './assets/scss/mac';
@import './assets/scss/fonts';

.transparent {
  color: transparent !important;
  width: 0px !important;
  height: 0px !important;
}
